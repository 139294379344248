<template>
  <div class="full-area padded vertically-centered desktop-wrapper">
    <div class="icon-wrapper">
      <Icons
        class="bounce-entry"
        icon="trophy"
      ></Icons>
    </div>
    <h1
      class="hide"
      :class="{'bounce-entry': wellDone }"
    >¡Bien hecho!</h1>
    <el-row>
      <el-button
        class="hide continue-button"
        :class="{'delay-entry': restartButton }"
        type="danger"
        round
        @click="restartSurvey()"
      >Comenzar de nuevo</el-button>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'DefaultResultsScreen',

  data () {
    return {
      wellDone: false,
      yourTime: false,
      testResults: false,
      restartButton: false
    }
  },

  components: {
    Icons: () => import('Components/base/Icons')
  },

  computed: {
    timeToCompletion () {
      const arr = this.$store.state.responses
      return arr.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.timeToCompletion
      }, 0)
    }
  },

  methods: {
    restartSurvey () {
      this.$emit('restart')
    }
  },

  filters: {
    msToMinutes: function (value) {
      const timeInSecs = Math.round(value / 100) / 10
      var mins = Math.floor(timeInSecs / 60)
      var secs = Math.floor(timeInSecs - mins * 60)
      return `${('00' + mins).slice(-2)}:${('00' + secs).slice(-2)}`
    }
  },

  mounted () {
    setTimeout(() => { this.wellDone = true }, 500)
    setTimeout(() => { this.yourTime = true }, 1000)
    setTimeout(() => { this.testResults = true }, 1500)
    setTimeout(() => { this.restartButton = true }, 10500)
  }
}
</script>

<style scoped lang="scss">
.icon-wrapper {
  height: 20vh;
  width: 30vw;
  margin: 0 auto 20px;

  @include breakpoint($micro) {
    height: 30vh;
    margin-bottom: 20px;
  }
  @include breakpoint($tablet) {
    height: 40vh;
    margin-bottom: 20px;
  }
  @include breakpoint($tablet) {
    height: 330px;
    width: 300px;
    margin-bottom: 20px;
  }
}

.full-area {
  transition: all 0.2s ease-in-out;
  box-shadow: none;
}

.hide {
  opacity: 0.0001;
}
.continue-button {
  margin-top: 20px;

  @include breakpoint($micro) {
    margin-top: 10px;
  }
}

h1 {
  @include breakpoint($micro) {
    margin-bottom: 0;
  }
}

h3 {
  @include breakpoint($micro) {
    margin: 0;
  }
}

</style>

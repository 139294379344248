import { render, staticRenderFns } from "./DefaultResultsScreen.vue?vue&type=template&id=c99207ce&scoped=true&"
import script from "./DefaultResultsScreen.vue?vue&type=script&lang=js&"
export * from "./DefaultResultsScreen.vue?vue&type=script&lang=js&"
import style0 from "./DefaultResultsScreen.vue?vue&type=style&index=0&id=c99207ce&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c99207ce",
  null
  
)

export default component.exports